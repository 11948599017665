body.HanumanChalisa {
  padding: 2rem 0;
  font-size: 18px;
  color: aliceblue;
  text-align: center;
  --primary-background-color: #282c34;
}
body.HanumanChalisa img {
  width: 250px;
  border-radius: 10px;
}
body.HanumanChalisa .App-link {
  color: #61dafb;
}
