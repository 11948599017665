.dark-mode {
  --primary-background-color: #091921;
  --clock-background-color: #091921;
  --border: 4px solid #091921;
  --box-shadow: 0 -15px 15px rgba(255, 255, 255, 0.05),
    inset 0 -15px 15px rgba(255, 255, 255, 0.05), 0 15px 15px rgba(0, 0, 0, 0.3),
    inset 0 15px 15px rgba(0, 0, 0, 0.3);
  --clock-before: #ffffff;
  --hr: #ff105e;
  --min: #ffffff;
  --sec: #6ea3fb;
}
.light-mode {
  --primary-background-color: #6ea3fb;
  --clock-background-color: #ffffff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.theme-toggle {
  position: fixed;
  top: 20px;
  right: 50px;
  z-index: 10000;
  background-color: red;
  cursor: pointer;
  padding: 10px;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: var(--primary-background-color, #ffffff);
}
.clock-wrapper {
  border-radius: 50%;
  background: var(--clock-background-color, #ffffff);
}
.clock {
  height: 350px;
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('assets/images/clock.png');
  background-size: cover;
  border: var(--border, 16px solid #ffffff);
  border-radius: 50%;
  box-shadow: var(
    --box-shadow,
    0 -15px 15px rgba(255, 255, 255, 0.05),
    inset -1px -2px 15px rgba(0, 0, 0, 0.11),
    0 15px 15px rgba(0, 0, 0, 0.3),
    inset 0 3px 15px rgba(0, 0, 0, 0.3)
  );
}

.clock::before {
  content: ' ';
  position: absolute;
  width: 15px;
  height: 15px;
  background: var(--clock-before, #858486);
  border-radius: 50%;
  z-index: 10000;
}

.clock .hour,
.clock .minute,
.clock .second {
  position: absolute;
}

.clock .hour,
.hr {
  width: 160px;
  height: 160px;
}

.clock .minute,
.min {
  width: 190px;
  height: 190px;
}

.clock .second,
.sec {
  width: 230px;
  height: 230px;
}

.hr,
.min,
.sec {
  display: flex;
  justify-content: center;
  position: absolute;
  border-radius: 50%;
}

.hr::before {
  content: ' ';
  position: absolute;
  width: 8px;
  height: 80px;
  background: var(--hr, #858486);
  z-index: 10;
  border-radius: 6px 6px 0 0;
}

.min::before {
  content: ' ';
  position: absolute;
  width: 4px;
  height: 90px;
  background: var(--min, #d6d4d6);
  z-index: 11;
  border-radius: 6px 6px 0 0;
}

.sec::before {
  content: ' ';
  position: absolute;
  width: 2px;
  height: 150px;
  background: var(--sec, #ff105e);
  z-index: 12;
  border-radius: 6px 6px 0 0;
}
